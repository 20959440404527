var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('div', [_c('h3', {
    staticClass: "text-xs font-medium neutral-500"
  }, [_vm._v("Employee's Photo")]), _vm.options.photoImage ? _c('img', {
    staticClass: "my-2",
    attrs: {
      "src": _vm.options.photoImage
    }
  }) : _c('img', {
    staticClass: "my-2",
    attrs: {
      "src": require("@/assets/images/image-ratio.jpg")
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Change Photo",
      "type": "secondary"
    },
    on: {
      "action": _vm.updatePhoto
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Name (based on ID)",
      "placeholder": "Employee's fullname",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.name,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "name", $$v);
      },
      expression: "options.name"
    }
  }), _c('div', {
    staticClass: "gender flex flex-col gap-3"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Gender ")]), _c('div', {
    staticClass: "flex gap-10"
  }, [_c('div', {
    staticClass: "leading-0 flex items-center gap-3 relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modeForm.gender,
      expression: "modeForm.gender"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "pria",
      "value": "PRIA",
      "disabled": _vm.modeForm === 'Edit'
    },
    domProps: {
      "checked": Array.isArray(_vm.modeForm.gender) ? _vm._i(_vm.modeForm.gender, "PRIA") > -1 : _vm.modeForm.gender
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.modeForm.gender,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "PRIA",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.modeForm, "gender", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.modeForm, "gender", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.modeForm, "gender", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "webinar"
    }
  }, [_vm._v("Male")])]), _c('div', {
    staticClass: "leading-0 flex items-center gap-3 relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modeForm.gender,
      expression: "modeForm.gender"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "wanita",
      "value": "WANITA",
      "disabled": _vm.modeForm === 'Detail'
    },
    domProps: {
      "checked": Array.isArray(_vm.modeForm.gender) ? _vm._i(_vm.modeForm.gender, "WANITA") > -1 : _vm.modeForm.gender
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.modeForm.gender,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "WANITA",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.modeForm, "gender", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.modeForm, "gender", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.modeForm, "gender", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "self-based"
    }
  }, [_vm._v("Female")])])])]), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Phone Number",
      "placeholder": "Employee's phone number",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.phoneNumber,
      callback: function callback($$v) {
        _vm.phoneNumber = $$v;
      },
      expression: "phoneNumber"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "ID Number",
      "placeholder": "Employee's ID number",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.civilNumber,
      callback: function callback($$v) {
        _vm.civilNumber = $$v;
      },
      expression: "civilNumber"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Birth Place",
      "placeholder": "Tempat lahir karyawan",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.placeBirth,
      callback: function callback($$v) {
        _vm.placeBirth = $$v;
      },
      expression: "placeBirth"
    }
  }), _c('TextField', {
    attrs: {
      "type": "date",
      "label": "Birth Date",
      "placeholder": "Tanggal lahir karyawan",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.dateBirth,
      callback: function callback($$v) {
        _vm.dateBirth = $$v;
      },
      expression: "dateBirth"
    }
  }), _vm._m(0), _vm._m(1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "religion flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Religion ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marital-status flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v("Marital Status")])]);
}]

export { render, staticRenderFns }