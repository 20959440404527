<template>
  <div class="flex flex-col gap-6">
    <div>
      <h3 class="text-xs font-medium neutral-500">Employee's Photo</h3>
      <img v-if="options.photoImage" class="my-2" :src="options.photoImage" />
      <img v-else class="my-2" src="@/assets/images/image-ratio.jpg" />
      <Button buttonText="Change Photo" type="secondary" @action="updatePhoto" />
    </div>
    <!-- <TextField type="text" label="Name 1" placeholder="Name 1" :borderEnabled="true" v-model="dataX.nama1" :disabled="modeForm === 'Detail'" />
    <TextField type="text" label="Name 2" placeholder="Name 2" :borderEnabled="true" v-model="dataX.nama2" :disabled="modeForm === 'Detail'" /> -->
    <TextField type="text" label="Name (based on ID)" placeholder="Employee's fullname" :borderEnabled="true" v-model="options.name" :disabled="modeForm === 'Detail'" />
    <div class="gender flex flex-col gap-3">
      <label class="text-xs font-medium neutral-500 pl-4"> Gender </label>
      <div class="flex gap-10">
        <div class="leading-0 flex items-center gap-3 relative">
          <input type="checkbox" id="pria" value="PRIA" v-model="modeForm.gender" class="styled-checkbox" :disabled="modeForm === 'Edit'" />
          <label for="webinar">Male</label>
        </div>
        <div class="leading-0 flex items-center gap-3 relative">
          <input type="checkbox" id="wanita" value="WANITA" v-model="modeForm.gender" class="styled-checkbox" :disabled="modeForm === 'Detail'" />
          <label for="self-based">Female</label>
        </div>
      </div>
    </div>
    <TextField type="text" label="Phone Number" placeholder="Employee's phone number" :borderEnabled="true" v-model="phoneNumber" :disabled="modeForm === 'Detail'" />
    <TextField type="text" label="ID Number" placeholder="Employee's ID number" :borderEnabled="true" v-model="civilNumber" :disabled="modeForm === 'Detail'" />
    <TextField type="text" label="Birth Place" placeholder="Tempat lahir karyawan" :borderEnabled="true" v-model="placeBirth" :disabled="modeForm === 'Detail'" />
    <TextField type="date" label="Birth Date" placeholder="Tanggal lahir karyawan" :borderEnabled="true" v-model="dateBirth" :disabled="modeForm === 'Detail'" />
    <div class="religion flex flex-col gap-1">
      <label class="text-xs font-medium neutral-500 pl-4"> Religion </label>
      <!-- <Dropdown :options="religions" optionLabel="name" v-model="modeForm.email" :default="modeForm.email" placeholder="Choose employee's religion" :disabled="modeForm === 'Detail'" /> -->
    </div>
    <div class="marital-status flex flex-col gap-1">
      <label class="text-xs font-medium neutral-500 pl-4">Marital Status</label>
      <!-- <Dropdown :options="maritals" optionLabel="name" v-model="modeForm.email" :default="modeForm.email" placeholder="Choose employee's marital status" :disabled="modeForm === 'Detail'" /> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      religions: [
        {
          name: 'Islam',
          value: 'ISLAM'
        },
        {
          name: 'Protestan',
          value: 'PROTESTAN'
        },
        {
          name: 'Katolik',
          value: 'KATOLIK'
        },
        {
          name: 'Hindu',
          value: 'HINDU'
        },
        {
          name: 'Budha',
          value: 'BUDHA'
        },
        {
          name: 'Konghucu',
          value: 'KONGHUCU'
        }
      ],
      maritals: [
        {
          name: 'TK0',
          value: 'TK0'
        },
        {
          name: 'TK1',
          value: 'TK1'
        },
        {
          name: 'TK2',
          value: 'TK2'
        },
        {
          name: 'TK3',
          value: 'TK3'
        },
        {
          name: 'K0',
          value: 'K0'
        },
        {
          name: 'K1',
          value: 'K1'
        },
        {
          name: 'K2',
          value: 'K2'
        },
        {
          name: 'K3',
          value: 'K3'
        }
      ]
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    options: {
      type: Object,
      default: () => ({})
    },
    modeForm: {
      type: String
    }
  },
  computed: {
    phoneNumber() {
      return this.options.phoneNumber || this.modeForm === 'Edit' || this.modeForm === 'Create New' ? this.options.phoneNumber : 'Data unavailable'
    },
    noKtp() {
      return this.options.noKtp || this.modeForm === 'Edit' || this.modeForm === 'Create New' ? this.options.noKtp : 'Data unavailable'
    },
    placeBirth() {
      return this.options.placeBirth || this.modeForm === 'Edit' || this.modeForm === 'Create New' ? this.options.placeBirth : 'Data unavailable'
    },
    dateBirth() {
      return this.options.dateBirth || this.modeForm === 'Edit' || this.modeForm === 'Create New' ? this.options.dateBirth : 'Data unavailable'
    }
  },
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Button" */ '@/components/Dropdown/Dropdown')
  },
  methods: {
    updatePhoto() {}
    // validatorPhoneNumber(value, forError) {
    //   if (value.length >= 15) {
    //     switch (forError) {
    //       case 'userPhoneNumber':
    //         this.userPhoneNumber = value.slice(0, 15)
    //         break
    //       case 'customerServiceNumber':
    //         this.customerServiceNumber = value.slice(0, 15)
    //         break
    //     }
    //     this.phoneInputType = 'text'
    //   } else {
    //     this.phoneInputType = 'number'
    //   }
    //   if (forError === 'customerServiceNumber' && value.length < 1) {
    //     this.generalError[forError] = undefined
    //   } else if (value[0] !== '0' || value[1] !== '8') {
    //     this.generalError[forError] = 'Must start with 08xxxx'
    //   } else if (value.length < 10) {
    //     this.generalError[forError] = 'Minimum 10 digits, Maximum 15 digits'
    //   } else {
    //     this.generalError[forError] = undefined
    //   }
    // }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  z-index: 10;
  left: 4px;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }

  // Box.
  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #3b82f6;
    border-radius: 10000px;
    margin-right: 10px;
  }

  // Box hover
  &:hover + label:before {
    background: #3b82f6;
    // opacity: 0.4;
  }

  // Box checked
  &:checked + label:before {
    background: #3b82f6;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
